import moment from 'moment';
import {
    DatePicker,
    Form,
} from 'antd';

export const ScopePageByDateRange = ({ dateRangePickerProps: { value, onChange } }) => (
    <Form.Item name="dateRange" label="Date Range">
        <DatePicker.RangePicker
            defaultValue={value} // need to use `defaultValue` instead of `value` prop for this antd component for some reason
            onCalendarChange={onChange}
            disabledDate={(current) => {
                // Disable dates after "Yesterday"
                const dateIsAfterYesterday = current && current.isAfter(moment().subtract(1, 'day'), 'day');
                if (dateIsAfterYesterday) {
                    return true;
                }

                // Disable date ranges which span more than 1 year
                if (value) {
                    const [selectedStartDate, selectedEndDate] = value;
                    const rangeIsTooLarge =
                        (selectedStartDate && current.diff(selectedStartDate, 'years', true) > 1) ||
                        (selectedEndDate && selectedEndDate.diff(current, 'years', true) > 1);
                    return rangeIsTooLarge;
                }
            }}
            ranges={{
                // Omit data from "Today" by Product team's request to not alarm users to drop in metrics
                Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
                'Last 7 days': [moment().subtract(7, 'days'), moment().subtract(1, 'day')],
                'Last 30 days': [moment().subtract(30, 'days'), moment().subtract(1, 'day')],
                'Last month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                ],
                'Month to date': [moment().startOf('month'), moment().subtract(1, 'day')],
                'Year to date': [moment().startOf('year'), moment().subtract(1, 'day')],
            }}
        />
    </Form.Item>
);

