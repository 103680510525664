import { QuestionCircleOutlined } from '@ant-design/icons';
import {
    Alert,
    Card,
    Col,
    PageHeader,
    Row,
    Tooltip,
    Table,
    Typography,
} from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import {
    OnceLoaded,
    ScopePageByOrg,
    ScopePageForm,
    useScopePageByOrg,
    ScopePageByDateRange,
    MetricsCard,
    Tooltips
} from '../../components';
import { PageContents } from '../../layout';
import api from '../../services/api';
import { SEARCH_PARAM_KEYS } from '../../services/constants';
import MetricsPie from './../Metrics/MetricsPie';
import { formatWithThousandCommas } from '../../services/utils';

import styles from '../../components/Metrics/Metrics.module.less';

const YESTERDAY = moment().subtract(1, 'day');

const MetricsPageContents = ({ org, dateRange }) => {
    const { id: orgId, name: orgName } = org;
    const [dateStartString, dateEndString] = dateRange;

    const swrKeyProps = [orgId, dateStartString, dateEndString];

    const orgScopedParams = {
        org_id: orgId,
        start_date: dateStartString,
        end_date: dateEndString,
    };

    const { data: mwrMetrics, error: mwrMetricsError } = useSWR(
        ['/MWRMetrics', ...swrKeyProps],
        () => api.getMessageWallRecoveryMetrics(orgScopedParams),
        { revalidateOnFocus: false }
    );
    const { data: adblockerMetrics, error: adBlockUsersError } = useSWR(
        ['/AdblockerBreakdown', ...swrKeyProps],
        () => api.getAdblockerBreakdownMetrics(orgScopedParams),
        { revalidateOnFocus: false }
    );
    const { data: performanceMetrics, error: performanceError } = useSWR(
        ['/RLPerformance', ...swrKeyProps],
        () => api.getRLPerformanceMetrics(orgScopedParams),
        { revalidateOnFocus: false }
    );

    const performanceData = useMemo(() => {
        if (performanceMetrics && performanceMetrics.metrics) {
            return performanceMetrics.metrics.map(v => ({ ...v, date: moment.utc(v.date).format('YYYY-MM-DD') }))
        }
        return [];
    }, [performanceMetrics])

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} xl={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={6}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <MetricsCard
                                        title="Recovered Revenue"
                                        value={mwrMetrics?.recovered_revenue}
                                        valueProps={{ prefix: '$', precision: 2 }}
                                        error={mwrMetricsError}
                                        help="The total Readership Link recovered revenue generated from monetizing AdBlock (Acceptable Ads) and non-AdBlock users throughout the date range specified."
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={6} xl={6}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <MetricsCard
                                        title="Renders"
                                        value={mwrMetrics?.renders}
                                        error={mwrMetricsError}
                                        help="How many times Readership Link was rendered on a user session throughout the date range specified."
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={6}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <MetricsCard
                                        title="Users Recovered"
                                        value={mwrMetrics?.recoveries}
                                        error={mwrMetricsError}
                                        help="How many users enabled AA or disabled Ad Blocker after seeing the Readership Link message throughout the date range specified."
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={6}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <MetricsCard
                                        title="Recovery Rate"
                                        value={mwrMetrics?.recovery_rate && mwrMetrics?.recovery_rate * 100}
                                        valueProps={{ suffix: '%', precision: 1 }}
                                        error={mwrMetricsError}
                                        help="Percentage of the renders led to users enabled AA or disabled Ad Blocker throughout the date range specified."
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <MetricsCard
                                        title="Subscription Redirect Rate"
                                        value={
                                            mwrMetrics?.primary_cta_redirect_rate &&
                                            mwrMetrics?.primary_cta_redirect_rate * 100
                                        }
                                        valueProps={{ suffix: '%', precision: 1 }}
                                        error={mwrMetricsError}
                                        help="Percentage of the renders that led to users clicks on subscription redirect button throughout the date range specified.
                      The rate value may be zero if Readership Link is not set up to custom CTA mode."
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <MetricsCard
                                        title="Dismiss Rate"
                                        value={mwrMetrics?.dismiss_rate && mwrMetrics?.dismiss_rate * 100}
                                        valueProps={{ suffix: '%', precision: 1 }}
                                        error={mwrMetricsError}
                                        help="Percentage of the renders that led the user to dismiss the message throughout the date range specified."
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <MetricsCard
                                        title="Bounce Rate"
                                        value={
                                            mwrMetrics?.navigation_away_rate && mwrMetrics?.navigation_away_rate * 100
                                        }
                                        valueProps={{ suffix: '%', precision: 1 }}
                                        error={mwrMetricsError}
                                        help="Percentage of the renders that led to the user abandoning the site during the date range specified."
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <br />
            <Row gutter={[16, 16]}>
                <Col xs={24} xl={12}>
                    <Card className={[styles.metricsCard, styles.dailyBreakdownCard]}>
                        <Typography.Title level={5}>Performance Metrics</Typography.Title>
                        <Tooltips
                            isBackfillIncluded={false}
                            downloadCSV={{
                                filename: `${orgName} - RL Performace metrics (${moment
                                    .utc(dateStartString)
                                    .format('YYYY-MM-DD')} - ${moment
                                        .utc(dateEndString)
                                        .format('YYYY-MM-DD')}).csv`, // removes "T00:00:00Z" from end of date string timestsamps
                                data: performanceData,
                            }}
                        />
                        {performanceError && <Alert message={performanceError} type="error" />}
                        <Table
                            key={`daily-breakdown-${orgId}-${dateStartString}-${dateEndString}`} // Ensures the pagination is reset when the table data changes
                            className={styles.table}
                            size="small"
                            scroll={{ x: 'max-content' }}
                            loading={!performanceData}
                            rowKey="date"
                            pagination={{
                                defaultPageSize: 4,
                                hideOnSinglePage: true,
                                pageSizeOptions: [4, 10, 20],
                            }}
                            dataSource={performanceData}
                            columns={[
                                {
                                    title: 'Date',
                                    dataIndex: 'date',
                                    fixed: 'left',
                                },
                                {
                                    title: 'AA Page Views',
                                    dataIndex: 'aa_page_views',
                                    render: formatWithThousandCommas,
                                },
                                {
                                    title: 'AB Page Views',
                                    dataIndex: 'ab_page_views',
                                    render: formatWithThousandCommas,
                                },
                                {
                                    title: 'AA Recoveries',
                                    dataIndex: 'aa_recoveries',
                                    render: formatWithThousandCommas,
                                },
                                {
                                    title: 'AB Recoveries',
                                    dataIndex: 'ab_recoveries',
                                    render: formatWithThousandCommas,
                                },
                                {
                                    title: 'Renders',
                                    dataIndex: 'renders',
                                    render: formatWithThousandCommas,
                                }, {
                                    title: 'Revenue',
                                    dataIndex: 'revenue',
                                    render: formatWithThousandCommas,
                                },
                            ]}
                        />
                    </Card>
                </Col>

                <Col xs={24} xl={12}>
                    <Card className={styles.metricsCard}>
                        <Typography.Title level={5}>Traffic Breakdown by AdBlocker Type</Typography.Title>
                        <Tooltip
                            title="Number of times Readership Link rendered to Ad Blocker traffic, broken down by renders to users that has Acceptable Ads supported on the extension and those that did not."
                            className={styles.metricsCardTooltip}
                            overlayStyle={{ maxWidth: 260 }}
                        >
                            <QuestionCircleOutlined />
                        </Tooltip>
                        <MetricsPie
                            data={adblockerMetrics}
                            error={adBlockUsersError}
                            angleField="count"
                            colorField="category"
                        />
                    </Card>
                </Col>
                <Alert
                    message="Please note that dashboard figures are estimated and may differ slightly from actualized totals."
                    type="info"
                    style={{ width: '100%' }}
                    showIcon
                />
            </Row>
        </>
    );
};

const useScopePageByDateRange = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dateStartParam = searchParams.get(SEARCH_PARAM_KEYS.DATE_START);
    const dateEndParam = searchParams.get(SEARCH_PARAM_KEYS.DATE_END);

    // Ensure start & end dates from params are valid and are not set for dates any later than "Yesterday"
    // (The default/fallback date range is 'Last 30 days' (excluding "Today"))
    let dateStart = moment(dateStartParam, 'YYYY-MM-DD');
    if (!dateStart.isValid() || dateStart.isAfter(YESTERDAY, 'day')) {
        dateStart = moment().subtract(30, 'days');
    }
    let dateEnd = moment(dateEndParam, 'YYYY-MM-DD');
    if (!dateEnd.isValid() || dateEnd.isAfter(YESTERDAY, 'day') || dateEnd.isBefore(dateStart)) {
        // (An end date set before the start date is invalid)
        dateEnd = YESTERDAY;
    }
    // Ensure date range doesn't span more than 1 year
    if (dateEnd.diff(dateStart, 'years', true) > 1) {
        // Set start date to 1 year before the end date
        dateStart = moment(dateEnd).subtract(1, 'year');
    }

    const dateStartString = moment.utc(dateStart.format('YYYY-MM-DD')).format();
    const dateEndString = moment.utc(dateEnd.format('YYYY-MM-DD')).endOf('day').format();

    const scopedDateRange = [dateStartString, dateEndString];

    const dateRangePickerProps = {
        value: [dateStart, dateEnd],
        onChange: useCallback(
            (selectedDateRange) => {
                const [dateStart, dateEnd] = selectedDateRange || [];

                const newSearchParams = new URLSearchParams(searchParams);
                if (dateStart && dateEnd) {
                    newSearchParams.set(SEARCH_PARAM_KEYS.DATE_START, dateStart.format('YYYY-MM-DD'));
                    newSearchParams.set(SEARCH_PARAM_KEYS.DATE_END, dateEnd.format('YYYY-MM-DD'));
                }
                setSearchParams(newSearchParams);
            },
            [searchParams, setSearchParams]
        ),
    };

    return { scopedDateRange, dateRangePickerProps };
};


const RLMetrics = () => {
    // Metrics displayed are scoped to a selected org & date range which are stored in their corresponding URL search params
    // (Updates to the selected org or date range will trigger new API calls to fetch updated metrics)
    const { scopedOrg, orgSelectProps, orgsError } = useScopePageByOrg();
    const { scopedDateRange, dateRangePickerProps } = useScopePageByDateRange();

    return (
        <>
            <PageHeader
                ghost={false}
                className={styles.pageHeader}
                title="Readership Link"
                extra={
                    <ScopePageForm>
                        <ScopePageByOrg scopedOrg={scopedOrg} orgSelectProps={orgSelectProps} />
                        <ScopePageByDateRange dateRangePickerProps={dateRangePickerProps} />
                    </ScopePageForm>
                }
            />
            <PageContents>
                {scopedOrg?.id > 0 ? <OnceLoaded
                    error={orgsError}
                    isLoading={!(scopedOrg && scopedDateRange)}
                    render={() => (
                        <MetricsPageContents
                            org={scopedOrg}
                            dateRange={scopedDateRange}
                        />
                    )}
                /> : <Alert message="choose organization" />}
            </PageContents>
        </>
    );
};
export default RLMetrics;
