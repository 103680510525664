import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import btLogo from '../../../assets/logos/bt-logo-long.png';
import montserratBoldFont from './Montserrat-Bold.ttf';
import montserratRegularFont from './Montserrat-Regular.ttf';
import montserratSemiBoldFont from './Montserrat-SemiBold.ttf';
import openSansItalicFont from './OpenSans-Italic.ttf';
import openSansRegularFont from './OpenSans-Regular.ttf';
import sourceCodeProRegularFont from './SourceCodePro-Regular.ttf';

// Used for titles/headers
Font.register({
  family: 'Montserrat',
  fonts: [
    { src: montserratRegularFont },
    { src: montserratSemiBoldFont, fontWeight: 600 },
    { src: montserratBoldFont, fontWeight: 'bold' },
  ],
});

// Used for regular body text
Font.register({
  family: 'Open Sans',
  fonts: [{ src: openSansRegularFont }, { src: openSansItalicFont, fontStyle: 'italic' }],
});

// Used for code, emails, etc.
Font.register({
  family: 'Source Code Pro',
  src: sourceCodeProRegularFont,
});

const styles = StyleSheet.create({
  logo: {
    position: 'absolute',
    right: 18,
    top: 18,
    width: 140,
  },
  body: {
    fontFamily: 'Open Sans',
    fontSize: 10,
    lineHeight: 1.3,
    padding: '1.68cm',
  },
  title: {
    color: '#0000FF',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 6,
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 6,
    marginTop: 12,
  },
  paragraph: {
    marginBottom: 10,
  },
  italic: {
    fontStyle: 'italic',
  },
  code: {
    backgroundColor: '#E6EAFF',
    color: '#0000FF',
    fontFamily: 'Source Code Pro',
    fontSize: 9.5,
  },
  codeBlock: {
    marginBottom: 10,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  codeBlockTight: {
    marginBottom: 10,
    paddingHorizontal: 8,
    paddingVertical: 6,
    fontSize: 8,
  },
  keyValueRow: {
    marginBottom: 8,
    marginHorizontal: 8,
  },
  keyColumn: {
    position: 'absolute',
    top: 0,
  },
  valuesColumn: {
    left: 125,
    top: 0,
  },
  valuesColumnSubLine: {
    left: 169,
  },
});

const IntegrationGuideDocTemplate = ({ headerScriptInstructions }) => (
  <Document>
    <Page style={styles.body} size="LETTER">
      <Image style={styles.logo} src={btLogo} fixed />

      <Text style={styles.title}>Blockthrough Integration Guide</Text>

      <Text style={styles.header}>1. Implementing the Blockthrough Header Script</Text>
      {headerScriptInstructions}

      <Text style={styles.header}>2. Adding GAM Key-values</Text>
      <Text style={styles.paragraph}>
        Please add the following key-values to your GAM account. One thing to note, all key-values
        should be predefined string values, and reporting on values must be enabled.
      </Text>
      <View style={styles.keyValueRow}>
        <Text style={styles.keyColumn}>
          • key: &nbsp;<Text style={styles.code}>up_recovery</Text>
        </Text>
        <Text style={styles.valuesColumn}>
          • values: &nbsp;<Text style={styles.code}>true</Text>,&nbsp;
          <Text style={styles.code}>false</Text>
        </Text>
      </View>
      <View style={styles.keyValueRow}>
        <Text style={styles.keyColumn}>
          • key: &nbsp;<Text style={styles.code}>up_bidder</Text>
        </Text>
        <Text style={styles.valuesColumn}>
          • values: &nbsp;
          <Text style={styles.italic}>
            (all of the SSP partners in `up-bidder-values.txt` (attached))
          </Text>
        </Text>
        <Text style={styles.valuesColumnSubLine}>
          e.g.&nbsp;
          <Text style={styles.code}>ix</Text>,&nbsp;
          <Text style={styles.code}>rubicon</Text>, etc.
        </Text>
      </View>
      <View style={styles.keyValueRow}>
        <Text style={styles.keyColumn}>
          • key: &nbsp;<Text style={styles.code}>up_refresh</Text>
        </Text>
        <Text style={styles.valuesColumn}>
          • values: &nbsp;
          <Text style={styles.italic}>every number from 0-30</Text>
        </Text>
      </View>
      <View style={styles.keyValueRow}>
        <Text style={styles.keyColumn}>
          • key: &nbsp;<Text style={styles.code}>up_tabrefresh</Text>
        </Text>
        <Text style={styles.valuesColumn}>
          • values: &nbsp;
          <Text style={styles.italic}>every number from 1-30</Text>
        </Text>
      </View>
      <View style={styles.keyValueRow}>
        <Text style={styles.keyColumn}>
          • key: &nbsp;<Text style={styles.code}>up_source</Text>
        </Text>
        <Text style={styles.valuesColumn}>
          • values: &nbsp;<Text style={styles.code}>bt</Text>
        </Text>
      </View>
      <View style={styles.keyValueRow}>
        <Text style={styles.keyColumn}>
          • key: &nbsp;<Text style={styles.code}>bt_mwr</Text>
        </Text>
        <Text style={styles.valuesColumn}>
          • values: &nbsp;<Text style={styles.code}>true</Text>
        </Text>
      </View>

      <Text style={styles.header}>3. Demand Configuration</Text>
      <Text style={styles.paragraph}>
        To ensure we are only setting up targeting for your programmatic demand, we request a
        service user account be granted to&nbsp;
        <Text style={styles.code}>blockthrough@reports-215419.iam.gserviceaccount.com</Text>, so we
        can automate the restriction of direct demand filling our ad slots.
      </Text>
      <Text style={styles.paragraph}>
        If you would like to fill with direct demand alongside programmatic demand, that can be
        accomplished by signing the MSA addendum. When running direct demand alongside programmatic,
        it is still recommended that we have API access to ensure we are only monetizing direct
        campaigns that are intended for your ad blocked traffic.
      </Text>

      <Text style={styles.header}>4. Reporting</Text>
      <Text style={styles.paragraph}>
        In order for us to automatically pull reporting for our AdX performance and GAM delivery,
        and to bill at the end of the month, please share trafficker access with 
        <Text style={styles.code}>adops2@blockthrough.com</Text>.
      </Text>

      <Text style={styles.header}>5. Go Live!</Text>
      <Text style={styles.paragraph}>
        After you've inserted our header script tag onto the page in step 1, our tech stays disabled
        from our side as we complete the integration set up and confirm everything is firing as
        expected. We will only set this live once you've given us the green light.
      </Text>
    </Page>
  </Document>
);

/**
 * Contents of the PDF detailing our *standard* BT integration instructions
 */
export const StandardIntegrationGuideDoc = ({ orgId }) => {
  const headerScriptInstructions = (
    <>
      <Text style={styles.paragraph}>
        Insert the <Text style={styles.code}>{`<script>`}</Text> tag below at the end of the&nbsp;
        <Text style={styles.code}>{`<head>`}</Text> section of your page markup. This is suitable
        for all static and dynamic web pages on which you wish to use our technology (regardless of
        the state or content of the page).
      </Text>
      <View style={[styles.code, styles.codeBlock]}>
        <Text>{`<head>`}</Text>
        <Text>&nbsp;&nbsp;{`...`}</Text>
        <Text>
          &nbsp;&nbsp;
          {`<script src="https://btloader.com/tag?o=${orgId}&upapi=true" async></script>`}
        </Text>
        <Text>{`</head>`}</Text>
      </View>
    </>
  );

  return <IntegrationGuideDocTemplate headerScriptInstructions={headerScriptInstructions} />;
};

/**
 * Contents of the PDF detailing our *(large-)network-specific* BT integration instructions
 */
export const NetworkIntegrationGuideDoc = ({ orgId, exampleWebsiteDomain = 'example.com' }) => {
  const headerScriptInstructions = (
    <>
      <Text style={styles.paragraph}>
        Insert the <Text style={styles.code}>{`<script>`}</Text> tag below at the end of the&nbsp;
        <Text style={styles.code}>{`<head>`}</Text> section of your page markup with the website's
        domain in place of <Text style={styles.code}>{`[[domain]]`}</Text> (e.g.{' '}
        <Text style={styles.code}>{`&domain=${exampleWebsiteDomain}`}</Text>). This is suitable for
        all static and dynamic web pages on which you wish to use our technology (regardless of the
        state or content of the page).
      </Text>
      <View style={[styles.code, styles.codeBlockTight]}>
        <Text>{`<head>`}</Text>
        <Text>&nbsp;&nbsp;{`...`}</Text>
        <Text>
          &nbsp;&nbsp;
          {`<script src="https://btloader.com/tag?o=${orgId}&domain=[[domain]]&upapi=true" async></script>`}
        </Text>
        <Text>{`</head>`}</Text>
      </View>
    </>
  );

  return <IntegrationGuideDocTemplate headerScriptInstructions={headerScriptInstructions} />;
};

