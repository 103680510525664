import {
    Space,
    Tooltip,
    Typography,
    Card,
    Statistic,
} from 'antd';
import { BarChartOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { isNil } from 'lodash';
import {
    DownloadCSV,
} from '../index';

import styles from './Metrics.module.less';

export const BackfillTooltip = () => (
    <Tooltip
        title="This data is affected by the inclusion of backfill revenue."
        overlayStyle={{ maxWidth: 260 }}
    >
        <BarChartOutlined />
    </Tooltip>
);

export const MetricsCard = ({ title, value, valueProps, error, isBackfillIncluded, help }) => (
    <Card className={styles.metricsCard}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Tooltips isBackfillIncluded={isBackfillIncluded} help={help} />
        {/* TODO: Handle error */}
        <Statistic className={styles.statistic} loading={isNil(value)} value={value} {...valueProps} />
    </Card>
);

export const HelpTooltip = ({ text }) => (
    <Tooltip title={text} className={styles.metricsCardTooltip} overlayStyle={{ maxWidth: 260 }}>
        <QuestionCircleOutlined />
    </Tooltip>
);

export const Tooltips = ({ help, downloadCSV, isBackfillIncluded }) => (
    <Space className={styles.metricsCardTooltips}>
        {isBackfillIncluded ? <BackfillTooltip /> : null}
        {downloadCSV ? <DownloadCSV {...downloadCSV} /> : null}
        {help ? <HelpTooltip text={help} /> : null}
    </Space>
);
