import Icon, {
  ApartmentOutlined,
  FilePdfOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  ReadOutlined
} from '@ant-design/icons';
import { Badge, Layout, Menu } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logos/bt-logo-compact-white.svg';
import { useSessionUser } from '../services/hooks';
import { useStore } from '../services/store';
import { DASHBOARD_HELPFUL_LINKS } from '../services/constants';

const Sidebar = () => {
  const {
    state: { isDev },
  } = useStore();

  const {
    isLoaded: userIsLoaded,
    isInternalUser: userIsInternalUser,
    hasAccessForAnyOrg: userHasAccess,
    hasAccessToRLMetrics,
  } = useSessionUser();

  let { pathname } = useLocation();
  const selectedMenuKey = pathname.split('/')[1] || 'metrics'; // root/home page (pathname: '/') is the "Dashboard" metrics page

  const logoBlock = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 80 }}>
      <Link to="/" style={{ display: 'inline-block', lineHeight: 1, padding: 5 }}>
        <Icon component={Logo} style={{ fontSize: 50 }} />
      </Link>
    </div>
  );

  return (
    <Layout.Sider breakpoint="lg" style={{ zIndex: 2 }}>
      {isDev ? <Badge.Ribbon text="DEV">{logoBlock}</Badge.Ribbon> : logoBlock}

      <Menu
        theme="dark"
        selectedKeys={[selectedMenuKey]}
        mode="inline"
        items={
          userIsLoaded
            ? [
                {
                  key: 'metrics',
                  label: <Link to="/">Dashboard</Link>,
                  icon: <HomeOutlined />,
                },
                hasAccessToRLMetrics && {
                  key: 'readership-link',
                  label: <Link to="readership-link">Readership Link</Link>,
                  icon: <ReadOutlined />,
                },
                ...(userIsInternalUser
                  ? [
                      {
                        key: 'orgs',
                        label: <Link to="/orgs">Organizations</Link>,
                        icon: <ApartmentOutlined />,
                      },
                    ]
                  : []),
                ...(userHasAccess({ permittedRoles: ['orgadmin'] })
                  ? [
                      {
                        key: 'users',
                        label: <Link to="/users">Users</Link>,
                        icon: <UserOutlined />,
                      },
                    ]
                  : []),
                {
                  key: 'reports',
                  label: <Link to="/reports">Reports</Link>,
                  icon: <FilePdfOutlined />,
                },
                ...(DASHBOARD_HELPFUL_LINKS[pathname]
                  ? [
                      {
                        key: 'helpfulLinks',
                        label: 'Knowledge Base',
                        children: DASHBOARD_HELPFUL_LINKS[pathname]?.map(({ name, link }) => {
                          return {
                            label: (
                              <a href={link} target="_blank" rel="noopener noreferrer">
                                {name}
                              </a>
                            ),
                            key: name,
                            icon: <QuestionCircleOutlined />,
                            style: { paddingLeft: '32px' },
                          };
                        }),
                        icon: <QuestionCircleOutlined />,
                      },
                    ]
                  : []),
              ]
            : []
        }
      />
    </Layout.Sider>
  );
};

export default Sidebar;
